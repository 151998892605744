/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useState } from "react";
import { graphql } from "gatsby";
import BannerSection from "~components/sections/BannerSection.jsx";
import ImageArticleSection from "~components/sections/ImageArticleSection.jsx";
import InstagramSection from "~components/sections/InstagramSection.jsx";
import SEO from "~components/SEO.jsx";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import PopUpSchoolSection from "../components/sections/PopUpSchoolSection";
import PopUpWorkplaceSection from "../components/sections/PopUpWorkplaceSection";

const GiveUsAHandPage = ({ data, location }) => {
  // ===========================================================================
  // context / ref / state
  const [overlaySchoolsActive, setOverlaySchoolsActive] = useState(false);
  const [overlayWorkplacesActive, setOverlayWorkplacesActive] = useState(false);

  // ===========================================================================
  // variables

  const cms = data.sanityGiveUsAHandPage;

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout>
        <PopUpSchoolSection
          overlayActive={overlaySchoolsActive}
          setOverlayActive={setOverlaySchoolsActive}
        />

        <PopUpWorkplaceSection
          overlayActive={overlayWorkplacesActive}
          setOverlayActive={setOverlayWorkplacesActive}
        />

        <BannerSection data={cms.atfBanner} />

        <ImageArticleSection
          data={cms.textImageOne}
          clickEvents={[
            {
              buttonText: `Donate Now`,
              buttonTarget: `/donate`,
              documentURL: null
            },
            {
              buttonText: `Create Fundraising Campaign`,
              buttonTarget: `https://eatupeventfundraising.raisely.com`,
              documentURL: null
            }
          ]}
        />

        <ImageArticleSection data={cms.textImageTwo} />

        <ImageArticleSection
          data={cms.textImageThree}
          onClick={() => {
            setOverlaySchoolsActive(true);
          }}
          clickEvents={[
            {
              buttonText: `Find Out More`,
              buttonTarget: null,
              documentURL: null
            },
            {
              buttonText: `Enquire`,
              buttonTarget: `/lets-talk`
            }
          ]}
        />

        <ImageArticleSection
          data={cms.textImageFour}
          onClick={() => {
            setOverlayWorkplacesActive(true);
          }}
          clickEvents={[
            {
              buttonText: `Find Out More`,
              buttonTarget: null,
              documentURL: `/uploads/some-pdf.pdf`
            },
            {
              buttonText: `Enquire`,
              buttonTarget: `/lets-talk`
            }
          ]}
        />

        <InstagramSection />
      </Layout>

      <Footer />
    </>
  );
};

export default GiveUsAHandPage;

export const query = graphql`
  query GiveUsAHandPage {
    sanityGiveUsAHandPage {
      title

      #

      atfBanner {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      textImageOne {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        _rawBody
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        imageAlign
      }

      #

      textImageTwo {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        _rawBody
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        imageAlign
      }

      #

      textImageThree {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        _rawBody
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        imageAlign
      }

      #

      textImageFour {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        _rawBody
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        imageAlign
      }

      #

      seoDescription
      seoKeywords
    }
  }
`;
